.css-1h06qz8-control {
  min-height: 15px !important;
}

.css-1ovlgij-control {
  min-height: 20px !important;
}

.css-12lz0u5-control {
  min-height: 18px !important;
}

.select__indicator {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding: 0px 8px 0px 8px !important;
}

.select__dropdown-indicator {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.css-1xc3v61-indicatorContainer {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.org-tree-container {
  background-color: transparent !important;
  display: flex !important;
  justify-content: left !important;
}

.org-tree-node-label {
  font-size: 14px !important;
}
.org-tree-node-label-inner {
  background-color: var(--white-color) !important;
}

.grayColor {
  color: var(--slategray-color) !important;
}

.tree_button_style {
  background-color: var(--darkLightSkyBlue-color) !important;
  color: var(--themeBlue-color) !important;
  padding: 8px 8.5px;
}

.org-tree-node-label-inner {
  background-color: var(--darkLightSkyBlue-color) !important;
  box-shadow: none !important;
}
.org-tree-node-label .org-tree-node-label-inner {
  font-size: 12px !important;
  color: var(--midnightblue-color);
}
