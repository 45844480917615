.accord-item {
  border: 0px !important;
  box-shadow: none !important;
}
.accordion-button {
  color: var(--black-color);
  font-size: 14px;
}

.accordion-body {
  padding: 0px !important;

  background-color: var(--white-color) !important;
}

.accordion-button::after {
  background-image: url("../icon-plus.svg");
  transform: scale(0.9) !important;
}
.accordion-button:not(.collapsed)::after {
  background-image: url("../icon-minus.svg");
}
.accordion-button:focus {
  z-index: 3;
  border-color: transparent !important;
  outline: none !important;
  box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
  /* background-color: white !important; */

  box-shadow: none !important;
  border: 0px !important;
}
.childaccordionbtn {
  background-color: var(--white-color) !important;
}
.childaccordionbtn::after {
  background-image: url("../icon-plus.svg") !important;
  color: var(--white-color) !important;
}
.childaccordionbtn:not(.collapsed)::after {
  background-image: url("../icon-minus.svg") !important;
}

.childaccordionbtn:not(.collapsed) {
  color: blueviolet;
}
.form-check-input {
  width: 12px !important;
  height: 12px;
}
.confirmAccordion {
  width: 90%;
}

.form-check-label {
  color: var(--black-color);
  font-weight: 400;
  font-size: 13px;
}
.mainheaderbtn {
  color: var(--black-color) !important;
  background-color: rgb(236, 245, 253) !important;
}

.fetchBtn {
  margin-right: 17%;
}
.css-1h06qz8-control {
  min-height: 15px !important;
}

.css-1ovlgij-control {
  min-height: 20px !important;
}
.css-12lz0u5-control {
  min-height: 18px !important;
}
.select__indicator {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding: 0px 8px 0px 8px !important;
}
.select__dropdown-indicator {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.css-1xc3v61-indicatorContainer {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.create_role {
  color: var(--themeBlue-color);
  font-weight: 500;
  font-size: 14px;
}
.table_bg {
  background-color: var(--darkLightSkyBlue-color) !important;
}
