.border_input_color {
  border: 1px solid var(--whitesmoke-color) !important;
}

.total_collection_button {
  background-color: var(--darkLightSkyBlue-color);
  border-radius: 5px;
}
.label_text_style {
  font-size: 16px;
  font-weight: 500;
}
.total_text_color {
  color: var(--themeBlue-color) !important;
}
.recieved_button {
  background-color: var(--lightGreen-color) !important;
  border-radius: 5px;
}
.activated_text_color {
  color: var(--green-color);
}
.Verified_button {
  background-color: var(--lightRed-color) !important;
  border-radius: 5px;
}
.deactivated_text_color {
  color: var(--red-color);
}
.expense_button {
  background-color: var(--whitesmoke-color) !important;
  border-radius: 5px;
}
.expense_text_color {
  color: var(--black-color);
}
.border_bottom_color {
  border-bottom: 1px solid var(--whitesmoke-color);
  padding-bottom: 8px;
}
.image_button_style {
  background-color: var(--darkLightSkyBlue-color) !important;
  color: var(--themeBlue-color) !important;
  padding: 8px 8.5px;
}
