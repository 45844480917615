.tabs button {
    padding: 0 17px;
    cursor: pointer;
    background: none;
    border: none;
    border-bottom: 2px solid transparent;
    font-size: 16px;
    transition: border-bottom 0.3s ease;
  }
  
  .tabs button.active {
    border-top: 1px solid var(--themeBlue-color);
    border-left: 1px solid var(--themeBlue-color);
    border-right: 1px solid var(--themeBlue-color);
    border-bottom: 0px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    color: var(--themeBlue-color);
  }
  .tabs button.inactive {
    border-bottom: 1px solid rgb(80, 80, 80);
    color: rgb(80, 80, 80);
  }
