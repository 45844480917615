.btn_style {
  padding-top: 7.5px;
  padding-bottom: 7.5px;
  padding-left: 12px;
  padding-right: 12px;
}

@media only screen and (min-width:200px) and (max-width:576px) {
  .btn_style {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 7px;
    padding-right: 7px;
  }


}