.border_datepicker {
  border: 1px solid var(--whitesmoke-color) !important;
}
.accordion-button {
  padding-top: 6.5px !important;
  padding-bottom: 7.5px !important;
}
.border_div_bottom {
  border-bottom: 1px solid var(--whitesmoke-color) !important;
}
.image_view {
  width: 140px;
  height: 100px;
  overflow: hidden;
}
.image_style {
  width: 140px;
  height: 120px;
}

.middle {
  width: 60%;
  margin: auto;
  border: 1px solid var(--whitesmoke-color);
  padding: 20px;
  background: #fff;
  border-radius: 8px;
}
.overflowDiv {
  overflow: auto;
  height: 400px;
  padding: 10px 20px 30px 20px;
}
