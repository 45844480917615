/* ****************Colors***************** */

:root {
  --lightgray-color: #f5f5f5;
  --whitesmoke-color: #cfd9e3;
  --themeBlue-color: #7966e4;
  --slategray-color: #717185;
  --lowSlate-color: #c4cdd5;
  --lowDarkSlate-color: #919eab;
  --lightSlategray-color: #7171851a;
  --white-color: #ffffff;
  --black-color: #000;
  --darkGray-color: #212b36;
  --midnightblue-color: #000031;
  --declineBtn-color: #dd4343;
  --doneBtn-color: #84cd6d;
  --themeLightBlue-color: #d2d2e4;
  --themeBlueBg-color: #b5aede;
  --lightVoilet-color: #f2e6ff;
  --extraLightGray-color: #dee3f3;
  --lightSkyBlue-color: #f1f4fd;
  --darkLightSkyBlue-color: #7966e41a;
  --gray-color: #808080;
  --golden-color: #feb415;
  --lightGolden-color: #feb4151a;
  --extraLightGray1-color: #d3d8e7;
  --themeColorGray-color: #77778f;
  --balancedGray-color: #888686;
  --rejectedColor-color: #dd4343;
  --green-color: #529c3b;
  --lightRed-color: #f3dede;
  --lightthemeBlue-color: #ece8ff;
  --gainsBoro-color: #dddddd;
  --shadowBlue-color: #8ecae6;
  --silverChalice-color: #acacac;
  --highColor-color: #edbcbc;
  --low-color: #dfefda;
  --medium-color: #ece8ff;
  --emergency-color: #edbcbc;
  --orange-color: #feb415;
  --red-color: #dd4343;
  --whitesmoke-color: #e6e6e6;
  --lightGreen-color: #0f7b4126;
  --lightSkyBg-color: #f1f4fd;
  --darkSkyBlue-color: #cbd3ec;
  --lowDark-color: #dfefda5a;
  --doggerBlue-color: #3da2ff;
  --brightPurple-color: #b007ff;
  --billingCard-color: #fff3e8;
  --choco-color: #b16002;
  --radioButtonBg-color: #007bff;
  --lightGolden: #fff6cc;
  --heighDarkGreen-color: #c6d9c0;
  --darkBlue: #05036b;
  --surf-color: #03f8fc;
  --offWhite-color: #f9efd7;
  --darkOrange-color: #ff8c00;
  --aliceBlue-color:  #f0f8ff;
}

/* *******************FontSize******************* */
