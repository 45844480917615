.grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 20px;
    height: 80vh;
    width: 100%;
    margin-top: 10px;
  }
  .chart-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    border: 1px solid var(--gainsBoro-color);
    padding: 10px;
    background-color: #f9f9f9;
    box-sizing: border-box;
  }
  .text-style {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 8px;
  }
