.notificationheader .table.table.table-bordered th:last-child {
     width: 114px;
}

.loader_style {
     z-index: 999999999999 !important;
     width: 100%;
     height: 100vh;
     position: fixed;
     display: flex;
     justify-content: center;
     align-items: center;
     background-color: rgba(0, 0, 0, 0.8);
}

.loader_circle {
     width: 50px;
     padding: 8px;
     aspect-ratio: 1;
     border-radius: 50%;
     background: var(--themeBlue-color);
     --_m:
          conic-gradient(#0000 10%, #000),
          linear-gradient(#000 0 0) content-box;
     -webkit-mask: var(--_m);
     mask: var(--_m);
     -webkit-mask-composite: source-out;
     mask-composite: subtract;
     animation: l3 1s infinite linear;

}


@keyframes l3 {
     to {
          transform: rotate(1turn)
     }
}