.my-class {
  overflow: auto;
  width: 100%;
  font-size: 12px;
}

.my-class table {
  height: 100%;
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 1px;
  text-align: left;
  font-size: 12px;
  border: 1px solid var(--whitesmoke-color);
}

.my-class caption {
  caption-side: top;
  text-align: left;
}

.my-class th {
  background-color: #ffffff;
  color: var(--midnightblue-color);
  padding: 5px 15px;
  font-weight: 600;
  font-size: 15px;
  background-color: var(--lightgray-color);
}
.my-class td {
  /* border: 1px solid var(--whitesmoke-color); */
  background-color: #ffffff;
  color: #000000;
  padding: 5px 15px;
  width: 100%;
}
.border_style {
  border-bottom: 0px !important;
}
.border_top_style {
  border-top: 0px !important;
}
.my-class h1 {
  font-size: 12px;
  text-align: center;
}
.font-weight-class {
  color: #000;
  font-weight: bold;
}
.font-weight-textAlign-class {
  color: #000;
  font-weight: bold;
  text-align: center;
}
.data_container {
  width: 100%;
  height: 100%;
}
.bottom_navigate {
  width: 100%;
  padding: 20px 0px;
  display: flex;
  justify-content: center;
}
.textStyle {
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.amount_style {
  font-weight: 700;
  color: var(--themeBlue-color) !important;
  font-size: 20px;
}

.padf_button_style {
  padding: 8px 8px;
}

.heading_style {
  color: var(--slategray-color);
  font-weight: 12;
  font-weight: 450;
}
.midnight_blue_color {
  color: var(--midnightblue-color) !important;
}
.normal_heading {
  color: var(--midnightblue-color);
  font-weight: 500;
  font-size: 14px;
}

@media print {
  @page {
    size: 200mm 230mm;
  }
}
