.mainHeading {
  font-size: 21px;
}
.bodText {
  color: var(--black-color);
  font-size: 15px;
}
.bodButton {
  padding: 5px 35px;
  border: none;
  border-radius: 5px !important;
  letter-spacing: 0.5;
}
.processIcon {
  font-size: 18px;
  padding-left: 5px;
}
