.border_div_style {
  border: 1px solid var(--whitesmoke-color) !important;
}
.border_bottom_style {
  border-bottom: 1px solid var(--whitesmoke-color);
  padding-bottom: 8px;
}
.excel_btn_style {
  background-color: var(--lightGreen-color) !important;
  padding: 8px 8px !important;
  color: var(--green-color) !important;
}
.border_bottom_table {
  border-bottom: 1px solid #e2e2e2;
}
.table_border_color {
  border: 1px solid #e2e2e2;
}
.table-heading {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.table-content {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
.table-content-child {
  width: 33%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2px;
  padding: 8px 0px;
}

.dark_bg_grey {
  background-color: var(--slategray-color) !important;
}
.low_bg_slate {
  background-color: var(--lowSlate-color) !important;
}
.low_dark_bg {
  background-color: var(--lowDarkSlate-color) !important;
}

.table_heading_text {
  color: var(--white-color) !important;
  font-weight: 500 !important;
  font-size: 12 !important;
}
.table_label_text {
  color: var(--midnightblue-color);
  font-weight: 500;
  font-size: 12;
}
