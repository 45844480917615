.beat_upload_button::-webkit-file-upload-button {
  visibility: hidden !important;
}
.beat_btn {
  width: 120px;
  background: var(--themeBlue-color);
  position: relative;
  height: 40px;
  cursor: pointer;
}
.beat_upload_button {
  width: 90px;
  position: absolute;
  top: 2px;
  right: 0px;
}
.beat_upload_button::before {
  content: "Upload File";
  display: inline-block;
  background: var(--themeBlue-color);
  color: var(--white-color);
  border: 0px solid transparent;
  padding: 6px 12px 6px 4px;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
}
.beat_upload_text {
  font-size: 15px;
}
.icon_color {
  color: var(--white-color);
}
.icon_color:hover {
  color: var(--white-color);
}
.beat_btn input:focus {
  border: 0px solid var(--themeBlue-color) !important;
  box-shadow: none !important;
}
.text_style {
  font-size: 13px !important;
}
.check_details_button {
  font-size: 13px;
  color: var(--themeBlue-color);
  text-decoration: underline;
  cursor: progress;
}


.record_button {
  background-color: var(--darkLightSkyBlue-color);
  border-radius: 5px;
}
.label_text_style {
  font-size: 16px;
  font-weight: 500;
}
.total_text_color {
  color: var(--themeBlue-color) !important;
}
.xl_button_style {
  padding: 8px 8px;
}

