.label_style {
  font-size: 14px;
  color: var(--slategray-color);
}
.text_style {
  font-size: 15px;
  color: var(--midnightblue-color) !important;
}
.text_style::placeholder {
  font-size: 15px;
  color: var(--midnightblue-color) !important;
}
.total_button {
  background-color: var(--darkLightSkyBlue-color);
  border-radius: 5px;
  width: 141px;
  height: 32px;
}
.total_buttons {
  background-color: var(--darkLightSkyBlue-color);
  border-radius: 5px;
  height: 32px;
}
.label_text_style {
  font-size: 16px;
  font-weight: 500;
}
.total_text_color {
  color: var(--themeBlue-color) !important;
}
.activated_button {
  background-color: var(--lightGreen-color) !important;
  border-radius: 5px;
  width: 141px;
  height: 32px;
}
.activated_text_color {
  color: var(--green-color);
}
.deactivated_button {
  background-color: var(--lightRed-color) !important;
  border-radius: 5px;
  height: 32px;
  width: 141px;
}
.deactivated_text_color {
  color: var(--red-color);
}

.status_style{
  height:32px !important
  }
  .button_width{
    width: 120px !important;
  }