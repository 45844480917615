.btn-custom-color {
  background-color: var(--brightPurple-color);
  color: var(--aliceBlue-color);
}
.btn-custom-color:hover {
  background-color: rgb(102, 45, 155);
  color: var(--aliceBlue-color);
}
.bottom-margin {
  margin-bottom: 0.5rem !important;
}
.custom-image-size {
  height: 40px;
  max-width: 40px;
}
.modal_image{
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-large-image {
  max-height: 100%;
  max-width: 100%;
}

.text-bg-pink {
  background-color: #ff00d9;
  color: var(--white-color);
}

.image_upload_style{
  background-color: var(--darkLightSkyBlue-color) !important;
  color: var(--themeBlue-color) !important;
  padding: 8px 8.5px;
}
.table_style tbody tr td{
  vertical-align: middle;
}


.fixed-table-header tbody {
  display: block;
  width: 100%;
  overflow: auto;
  height: 320px;
}
 
.fixed-table-header thead tr {
  width: 100%;
  display: block;

}
.fixed-table-header tbody tr {
  width: 100%;
  display: block;


}

.fixed-table-header th{
  text-align: left !important;
  width: 100px !important;

}

.fixed-table-header td {
  padding: 5px !important;
  text-align: left !important;
  width: 100px !important;


}
 