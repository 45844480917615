.title_style {
  font-weight: 600;
  color: var(--midnightblue-color);
}

.close_modal {
  cursor: pointer;
  font-weight: 600;
  color: var(--midnightblue-color);
  font-size:22px;
  transform:scale();
  transition:0.3s;

}
.close_modal:hover{
  font-size:26px;
  transform:scale(1.5)
}