.purchase_upload_button::-webkit-file-upload-button {
    visibility: hidden !important;
  }
  .purchase_btn {
    width: 120px;
    background: var(--themeBlue-color);
    position: relative;
    height: 40px;
    cursor: pointer;
  }
  .purchase_btn input:focus {
    border: 0px solid var(--themeBlue-color) !important;
    box-shadow: none !important;
  }
  .purchase_upload_button {
    width: 90px;
    position: absolute;
    top: 2px;
    right: 0px;
  }
  .purchase_upload_button::before {
    content: "Upload File";
    display: inline-block;
    background: var(--themeBlue-color);
    color: var(--white-color);
    border: 0px solid transparent;
    padding: 6px 12px 6px 4px;
    outline: none;
    white-space: nowrap;
    cursor: pointer;
  }
  .beat_upload_text {
    font-size: 15px;
  }
  .text_style {
    font-size: 13px !important;
  }