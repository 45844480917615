.main_container {
  width: 100%;
  height: 91%;
  padding-bottom: 8px;

}

.top_container {
  height: 10%;
}

.bottom_container {
  width: 100%;
  height: 90%;
  background: var(--white-color);
  border-radius: 8px;
  overflow-y: auto;
  padding-left: 0px !important;
  padding-right: 0px !important;
  position: relative;
  overflow-x: unset !important;
}

.bottom_container::-webkit-scrollbar {
  width: 10px;
  height: 0px;
}

.bottom_container::-webkit-scrollbar-track {
  background: var(--darkLightSkyBlue-color) !important;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.bottom_container::-webkit-scrollbar-thumb {
  background: var(--lowSlate-color) !important;
  border-radius: 10px;
}

.beatTextStyle {
  font-weight: 600;
  font-size: 18px;
}

.beatDailyTextStyle {
  font-size: 16px;
}

.midnightBlueColor {
  color: var(--midnightblue-color);
}

.grayColor {
  color: var(--slategray-color);
}

.titleStyle {
  font-weight: 500;
  color: var(--midnightblue-color);
  font-size: 16px;
}

@media only screen and (max-width: 575px) {
  .titleStyle {
    font-size: 14px;
    color: var(--themeBlue-color);
    background-color: var(--darkLightSkyBlue-color);
    padding: 4px 8px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .beatTextStyle {
    font-weight: 500 !important;
    font-size: 17px !important;
  }
}