.border_div_style {
  border: 1px solid var(--whitesmoke-color) !important;
}
.border_bottom_style {
  border-bottom: 1px solid var(--whitesmoke-color);
  padding-bottom: 8px;
}

.party_name_style {
  font-weight: 500;
  color: var(--midnightblue-color) !important;
  font-size: 12px !important;
  flex-wrap: nowrap !important;
}
.date_style {
  color: var(--midnightblue-color) !important;
  font-size: 11.5px !important;
  flex-wrap: nowrap !important;
}

.label_style {
  font-size: 14px;
  color: var(--slategray-color);
}
.text_style {
  font-size: 15px;
  color: var(--midnightblue-color) !important;
}
.text_style::placeholder {
  font-size: 15px;
  color: var(--midnightblue-color) !important;
}
