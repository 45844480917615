.btn-custom-color {
  background-color: var(--brightPurple-color);
  color: var(--aliceBlue-color);
}

.btn-custom-color:hover {
  background-color: rgb(102, 45, 155);
  color: var(--aliceBlue-color);
}

.bottom-margin {
  margin-bottom: 0.5rem !important;
}

.custom-image-size {
  height: 40px;
  max-width: 40px;
}

.modal_image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-large-image {
  max-height: 100%;
  max-width: 100%;
}

.text-bg-pink {
  background-color: #ff00d9;
  color: var(--white-color);
}

.image_upload_style {
  background-color: var(--darkLightSkyBlue-color) !important;
  color: var(--themeBlue-color) !important;
  padding: 8px 8.5px;
}

.table_style tbody tr td {
  vertical-align: middle;
}


.manage-product-table tbody {
  width: 100%;
  overflow: auto;
  height: 240px;
}

.manage-product-table thead tr {
  width: 100%;
}

.manage-product-table tbody tr {
  width: 100%;


}

.manage-product-table th {
  font-size: 11px !important;

}

.manage-product-table td {
  padding: 5px !important;
  text-transform: capitalize !important;
}

.error-text {
  color: red !important;
}


.manage-product-table tbody::-webkit-scrollbar {
  width: 10px;
  height: 0px;
}

.manage-product-table tbody::-webkit-scrollbar-track {
  background: var(--darkLightSkyBlue-color) !important;

}

.manage-product-table tbody::-webkit-scrollbar-thumb {
  background: var(--lowSlate-color) !important;
  border-radius: 4px;
}