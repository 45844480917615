.login_left_view {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

}

.login_left_view_inner {
    width: 60%;
}


.login_text_style {
    color: var(--midnightblue-color);
    font-weight: bold;
    font-size: 30px;
}

.login_simple_text_style {
    color: var(--slategray-color);
    font-size: 16px;
}

.login_label_style {
    font-size: 14px;
    color: var(--slategray-color);

}

.login_user_input {
    position: relative;

}

.login_user_input input {
    padding: 7px 10px;
    border: 1px solid var(--whitesmoke-color);
}

.login_user_input input:focus {
    border: 1px solid var(--themeBlue-color);
    box-shadow: none;
}

.padding_style_button {
    padding: 7px 10px;
    margin: 0px 1px;
}

.forgot_password {
    font-size: 12px;
    color: var(--themeBlue-color);
    text-decoration: none;
    font-weight: 400;
}

.checkbox_style {
    width: 17px;
    height: 17px;
}

.remember_style {
    font-size: 12px;
    color: var(--slategray-color);
}

.passwordHideStyle {
    position: absolute;
    width: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0px;
    top: 8px;
}

.otp-input {
  width: 2.25rem;
  height: 2.25rem;
  text-align: center;
  font-size: 1.5rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  margin: 0 0.25rem;
}

.otp-input:focus {
  border-color: #80bdff;
  outline: none;
}




@media only screen and (min-width:200px) and (max-width:576px) {
    .login_left_view_inner {
        width: 85%;
    }

}