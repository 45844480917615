.forget_left_view {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

}

.forget_left_view_inner {
  width: 60%;
}

.otpVerifiedView {
  width: 50% !important;
}

.forget_password_container {
  width: 58%;
}

.forget_text_style {
  color: var(--midnightblue-color);
  font-weight: bold;
  font-size: 30px;
}

.forget_simple_text_style {
  color: var(--slategray-color);
  font-size: 16px;
}

.forget_label_style {
  font-size: 14px;
  color: var(--slategray-color);

}

.forget_user_input {
  position: relative;

}

.forget_user_input input {
  padding: 7px 10px;
  border: 1px solid var(--whitesmoke-color);
}

.forget_user_input input:focus {
  border: 1px solid var(--themeBlue-color);
  box-shadow: none;
}

.padding_style_button {
  padding: 7px 10px;
  margin: 0px 1px;
}

.forgot_password {
  font-size: 12px;
  color: var(--themeBlue-color);
  text-decoration: none;
  font-weight: 400;
}

.checkbox_style {
  width: 17px;
  height: 17px;
}

.remember_style {
  font-size: 12px;
  color: var(--slategray-color);
}

.passwordHideStyle {
  position: absolute;
  width: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0px;
  top: 8px;
}

.leftContainerImage {
  position: relative;
}

.circleImage {
  width: 100px;
  height: 100px;
  position: absolute;
  right: 75px;
  top: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circleImage img {
  width: 100%;
  height: 100%;
}

.circleImage span {
  font-size: 70px;
  font-weight: 500;
  position: absolute;
  font-family: sans-serif;
  color: var(--white-color);
}


.verifyOtpView img {
  width: 450px;
  height: 450px;
}


.backView {
  padding: 3px 5px;
  border-radius: 4px;
  border: 1px solid var(--themeBlue-color);
  position: absolute;
  top: 25px;
  left: 20px;
  cursor: pointer;
}

.otp-input {
  width: 42px !important;
  height: 40px !important;
  border-radius: 10 !important;
  border: 0.5px solid #CFD9E3 !important;
  text-align: center !important;
}

.otp-input:focus {
  outline: 1px solid var(--themeBlue-color) !important;
}

.forget_number_style {
  color: var(--themeBlue-color);
  font-size: 15px;
}


@media only screen and (min-width:200px) and (max-width:576px) {
  .forget_left_view_inner {
    width: 85%;
  }

  .otpVerifiedView {
    width: 85% !important;
  }

  .forget_password_container {
    width: 85%;
  }

}