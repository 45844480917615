.center-alignment{
justify-content: center !important;
align-items: center !important;
align-content: center !important;
align-self: center !important;
}
.Image{
    width: 130px !important;
    height: 100px !important;
    margin-top: 10px;
}
.color_style{
    color: var(--themeBlue-color);
}