.innerContainer {
  width: 50%;
  background-color:var(--white-color);
}

.title-bankAccound {
  margin-bottom: 2em;
}
.addBankBtn {
  height: 35px !important;
  margin-top: 20px;
}
.select_bank_dropdown {
  padding: 10px !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.closeModal {
  position: absolute;
  top: -35px;
  right: 15px;
  cursor: pointer;
}
