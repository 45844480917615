/* customBootstrap.css */

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.borderBottom {
  border-bottom: 1px solid #dee2e6;
}

.borderTop {
  border-top: 1px solid #dee2e6;
}

.borderLeft {
  border-left: 1px solid #dee2e6;
}

.borderRight {
  border-right: 1px solid #dee2e6;
}

.p0 {
  padding: 0 !important;
}

.m0 {
  margin: 0 !important;
}

.p2 {
  padding: 0.5rem !important;
}

.p3 {
  padding: 1rem !important;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.colmd4,
.colmd6,
.colmd8 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.colmd4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.colmd6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.colmd8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.textCenter {
  text-align: center !important;
}

.textEend {
  text-align: right !important;
}

.fwBold {
  font-weight: bold !important;
}

.ps2 {
  padding-left: 0.5rem !important;
}

.pe2 {
  padding-right: 0.5rem !important;
}

.justifycontentbetween {
  justify-content: space-between !important;
}

.justifycontentcenter {
  justify-content: center !important;
}
.fontSize{
    font-size: 16px !important;
}
.RupeeFont{
    font-size: 14px !important;
}