.modal_header {
  width: 400px !important;
}
.show-attributes {
  color: blue;
  text-decoration: underline;
}
.deletebtn {
  color: rgb(255, 0, 0);
  text-decoration: underline;
}
.saveDltBtnStyle{
  display:flex;
  gap:10px
}