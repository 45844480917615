.label_style {
  font-size: 14px;
  color: var(--midnightblue-color);
}

.org-tree-node-label {
  font-size: 14px !important;
}
.org-tree-node-label-inner {
  background-color: var(--white-color) !important;
}

.grayColor {
  color: var(--slategray-color) !important;
}

.org-tree-node-label-inner {
  background-color: var(--darkLightSkyBlue-color) !important;
  box-shadow: none !important;
}
.org-tree-node-label .org-tree-node-label-inner {
  font-size: 12px !important;
  color: var(--midnightblue-color);
}
