.image_view {
  width: 140px;
  height: 100px;
  overflow: hidden;
}

.image_style {
  width: 140px;
  height: 120px;
}

.title_style {
  font-weight: 600;
  font-size: 18px !important;
  color: var(--midnightblue-color) !important;
}

.beat_sample {
  font-size: 15px;
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden !important;
}

.custom-file-input {
  width: 80px;
}

.custom-file-input::before {
  content: "Choose File";
  text-decoration: underline;
  display: inline-block;
  background: var(--white-color);
  color: var(--themeBlue-color);
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 6px 12px 6px 4px;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
}

.custom-file-input:before {
  box-shadow: none !important;
  border: 0px solid transparent;
}

@media only screen and (min-width:200px) and (max-width:576px) {
  .image_view {
    width: 120px !important;
    height: 80px !important;
  }

  .image_style {
    width: 120px !important;
    height: 100px !important;
  }

  .title_style {
    font-size: 16px !important;
  }

  .beat_sample {
    font-size: 13px;
  }

}