/* ****************Header.css*********************/
.header {
  width: 100%;
  display: flex;
  align-items: center;
  height: 9%;
  box-shadow: 0px 0.3px 0.3px 0px var(--lightgray-color);
}

.bgheader {
  background-color: var(--white-color);
}

.user_profile_style {
  font-size: 14px;
  font-weight: 600;
  color: #000031;
}

.agencyTitleStyle {
  font-size: 16px;
}

.adminMenu .dropdown-toggle::after {
  display: none !important;
}

.userDropdown {
  border-radius: 0px !important;
  inset: none !important;
  width: 210px;
  position: absolute;
  top: 13% !important;
  right: -13px !important;
  background-color: #000;
  z-index: 999 !important;
}

.userDropdown li {
  list-style: none;
  display: flex;
  line-height: 35px;
  gap: 10px;
  padding: 4px 35px 4px 20px;
  border-bottom: 1px solid var(--white-color);
  font-size: 20px !important;
}

.userDropdown-text {
  color: var(--white-color);
  font-size: 15px;
  font-weight: 600;
}

.userDropdown-icon {
  color: var(--white-color);
  font-size: 15px;
}

.userDropdown li:hover {
  background-color: var(--whitesmoke-color);
}

.userDropdown li:hover .userDropdown-text {
  color: #e00462;
}

.userDropdown li:hover .userDropdown-icon {
  color: #e00462;
}

.userDropdown li:nth-child(3) {
  border-bottom: none !important;
}

.notification_container {
  cursor: pointer;
}

.notification {
  width: 19px;
  height: 19px;
  border-radius: 50%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -8px;
  right: -8px;
  background: #feb415;
}

.notification_text {
  font-size: 10px;
  color: #ffffff;
}

.adminLogo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--themeBlue-color);
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--themeBlue-color);
  background-color: var(--darkLightSkyBlue-color);
}

.quick_register {
  background-color: var(--darkLightSkyBlue-color);
  padding: 6px 15px;
  border-radius: 5px;
}

.quick_register span {
  color: var(--themeBlue-color);
  text-decoration: none;
  text-align: center;
  cursor: pointer;

}

@media only screen and (min-width:200px) and (max-width:768px) {
  .adminLogo {
    width: 35px;
    height: 35px;
  }

}