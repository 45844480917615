.image_view {
  width: 140px;
  height: 100px;
  overflow: hidden;
}
.image_style {
  width: 140px;
  height: 120px;
}

.beatTextStyle {
  font-weight: 600;
  font-size: 18px;
}
.beatDailyTextStyle {
  font-size: 16px;
}
.midnightBlueColor {
  color: var(--midnightblue-color);
}
.grayColor {
  color: var(--slategray-color);
}
