.flex_container {
  width: 100%;
  padding-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.imageStyle {
  width: 100px;
  height: 90px;
}

.emptyTextHeading {
  font-weight: 600;
  font-size: 18px;
}

@media only screen and (max-width: 575px) {
  .imageStyle {
    width: 80px !important;
    height: 70px !important;
  }

  .emptyTextHeading {
    font-weight: 500 !important;
    font-size: 14px !important;
  }
}