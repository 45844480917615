* {
  font-size: 12px;
}

.themeColorBlue {
  color: var(--themeBlue-color) !important;
}

.midnightBlueColor {
  color: var(--midnightblue-color) !important;
}

.grayColor {
  color: var(--slategray-color) !important;
}

.lightGreenColor {
  background-color: var(--lightGreen-color) !important;
}

.lightRedColor {
  background-color: var(--lightRed-color);
}

.redColor {
  color: var(--rejectedColor-color);
}


.containermain {
  min-width: 100%;
  display: flex;
}

.sideMenus {
  width: 18%;
  height: 100vh;
  transition: all 0.2s;
  z-index: 999 !important;
  z-index: 21;
  background-color: #ffffff;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.05);
  margin-right: 0px !important;
  padding-right: 0px !important;
}

main {
  width: 82%;
  height: 100vh;
  background-color: var(--lightSkyBlue-color);

}

.mainExpand {
  width: 94.5% !important;
}

.paddingStyle {
  padding: 7px 0px;
}

.sideMenuCollapse {
  width: 5.5% !important;
  background: #232c3d !important;
}

@keyframes shadowSpread {
  0% {
    box-shadow: 0 0 10px var(--themeBlue-color);
  }

  50% {
    box-shadow: 0 0 150px var(--themeBlue-color);

  }

  100% {
    box-shadow: 0 0 10px var(--themeBlue-color);

  }
}


.barsDivStyle {
  width: 28px;
  height: 28px;
  background-color: var(--themeBlue-color) !important;
  border-radius: 5px;
  box-shadow: 0px 0px 0px 2px var(--whitesmoke-color);
  cursor: pointer;
  animation: shadowSpread 3s infinite;
}

.scrollableSideMneu {
  width: 17%;
  z-index: 22 !important;
  flex-wrap: nowrap !important;
  height: 87vh !important;
  padding-bottom: 10px !important;
  transition: all 1s;
  -webkit-transition: all 1s;
  overflow-y: auto;
}


.scrollableSideMneu::-webkit-scrollbar {
  width: 10px;
  margin-top: 10px;

}

.scrollableSideMneu::-webkit-scrollbar-track {
  background: var(--darkLightSkyBlue-color) !important;
  border-radius: 10px;
  margin-top: 10px;
}

.scrollableSideMneu::-webkit-scrollbar-thumb {
  background: var(--lowSlate-color) !important;
  border-radius: 10px;
  margin-top: 10px;


}

.menuTextStyle {
  font-size: 15.5px;
  font-weight: 400;
  margin-left: 10px;
}

.innerMenuTextStyle {
  font-size: 14px;
  font-weight: 400;

  /* margin-left: 10px; */
}

.innerMenuDiv {
  border-left: 1px solid #e3e7eb;
  margin-left: 9px;
  padding-left: 11px;
  padding-right: 2px;
  border-radius: 4px;
}

.innerMenuDivItem {
  padding-left: 9px;
  overflow: hidden;
  padding-right: 10px !important;
  border-radius: 4px;
}

.innerMenuDivItemActive {
  background-color: var(--lightSkyBlue-color);
}

.greyColorStyle {
  color: #717185;
}

.blueColorStyle {
  color: #7966e4;
  font-weight: 500;
}



.barIconPositionInDevices {
  position: absolute;
  top: 20px !important;
  right: -12px;
  overflow: hidden;

}

.closeIcon {
  width: 28px;
  height: 28px;
  background-color: var(--themeBlue-color);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

}

.closeIconAnimate {
  color: var(--white-color);
}

.mainMenu {
  width: 100%;
}

.mainMenuInnerIsopenMenu {
  width: 100% !important;
}

.mainMenuInnerIsCloseMenu {
  width: 100%;
  display: flex;
  justify-content: center;
  border-bottom: 0.5px solid var(--gray-color);
}

.mainMenuInnerIsCloseMenu:hover {
  background-color: var(--gray-color);
}

.isOpen_MenubarIcon {
  width: 100%;
  display: flex;
  justify-content: center;
}

.sideMenuLeftRow {
  padding: 10px 10px;
}

.isOpen_MenubarIcon:hover {
  background-color: var(--gray-color);
}

.sidebarNavigation::-webkit-scrollbar {
  width: 0;
  display: none;
}

.row {
  padding: 0 !important;
  margin: 0 !important;
}

.zIndex {
  z-index: 1100;
}

.dropdown-menu {
  --bs-dropdown-zindex: 2000 !important;
}

.block {
  display: block;
}

.valignCenter th {
  width: 100%;
  vertical-align: middle;
  text-align: center;
}

.mb30 {
  margin-bottom: 30px;
}

#menu {
  width: 100%;
}

h6 {
  background-color: #3d3b3b;
  width: fit-content;
  padding: 12px 20px;
  color: #f5f5f5;
  border-bottom-right-radius: 19px;
  height: 40px;
  font-size: 14px;
  font-weight: 500 !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt25 {
  margin-top: 25px !important;
}

.formatBODmsg {
  text-align: left;
  font-size: 14px;
}

.table_th {
  border: 2px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.tableHeader {
  background-color: #000000;
  color: var(--white-color);
  font-weight: 600;
}

.tableRow {
  background-color: var(--white-color);
  color: var(--black-color);
  font-weight: 600;
}

.registerLink {
  background-color: #151515;
  text-align: center;
  padding: 20px;
  margin-top: 20px;
  color: var(--white-color);
}

.invoiceHeader {
  text-align: center;
  background-color: var(--black-color);
  color: var(--white-color);
  padding: 15px;
}

.invoiceButton {
  width: 100%;
  background-color: var(--green-color);
  padding: 10px;
  border: none;
}

.otpdiv {
  padding: 10px;
  background-color: var(--white-color);
  margin-top: 35px;
}

.otpinput {
  width: 100%;
  padding: 10;
  padding: 15px;
}

.errormsg {
  text-align: center;
  font-weight: 700;
  color: var(--red-color);
  background-color: var(--whitesmoke-color);
  padding: 15px;
}

.updatesection {
  padding: 10px;
  text-align: left;
  background-color: var(--white-color);
  font-weight: 600;
}

.alertspan {
  padding: 5px;
  border: 1 ps solid;
  border: 1px solid;
  border-radius: 25px;
  cursor: pointer;
  background-color: var(--red-color);
  border: 2px solid var(--white-color);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.alertbadge {
  position: absolute;
}

.react-bootstrap-table table {
  table-layout: unset !important;
}

.bgActiveMenue {
  background-color: var(--white-color);
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinner-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 80px;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid var(--lightgray-color);
  border-top: 10px solid #383636;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.editicon:hover {
  color: rgb(183, 239, 183) !important;
}

.alignRight {
  float: right;
}

.rbt-menu {
  background-color: var(--white-color) !important;
  border: 1px solid var(--black-color);
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  height: fit-content !important;
  max-height: 125px !important;
  z-index: 4 !important;
}

.rbt-menu a {
  border-bottom: 1px solid var(--lightgray-color);
}

.advanceMapingBottomBtn {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 20px;
}

.advancemappingBtn {
  font-size: 14px;
  height: 35px !important;
}

.App {
  background-color: var(--white-color);
}

.Auth-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: #f0f0f0;
}

.box-outer {
  width: 400px;
  height: 420px;
}

.box {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
  padding-left: 2px;
  padding-right: 2px;
}

.box::before {
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  transform-origin: bottom right;
  background: linear-gradient(0deg,
      transparent,
      transparent,
      #192655,
      #192655,
      #192655);
  z-index: 1;
  animation: animate 24s linear infinite;
}

.box::after {
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  transform-origin: bottom right;
  background: linear-gradient(0deg,
      transparent,
      transparent,
      green,
      green,
      green);
  z-index: 1;
  animation: animate 24s linear infinite;
  animation-delay: -12s;
}

.border-line {
  position: absolute;
  top: 0;
  inset: 0;
}

.border-line::before {
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  transform-origin: bottom right;
  background: linear-gradient(0deg,
      transparent,
      transparent,
      darkorange,
      darkorange,
      darkorange);
  z-index: 1;
  animation: animate 24s linear infinite;
  animation-delay: -6s;
}

.border-line::after {
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  transform-origin: bottom right;
  background: linear-gradient(0deg,
      transparent,
      transparent,
      red,
      #512b81,
      #512b81);
  z-index: 1;
  animation: animate 24s linear infinite;
  animation-delay: -18s;
}

.Auth-form {
  width: 98%;
  height: 98%;
  margin-left: 0.5%;
  margin-top: 1%;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 30px;

  border-radius: 8px;
  background-color: #ffffff;
  z-index: 22 !important;
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.Auth-form-content {
  padding-left: 5%;
  padding-right: 5%;
}

.Auth-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 16px;
  color: rgb(34, 34, 34);
  font-weight: 600;
}

.login_upper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0%;
  background-color: #e1e5e8;
  clip-path: polygon(50% 71%, 0 0, 100% 0);
}

.normal-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.normal-form {
  width: 420px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 8px;
  background: #ffffff;
}

.normal-form-large {
  width: 800px;
}

.normal-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.normal-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 16px !important;
  color: #fff !important;
  font-weight: 600 !important;
  background-color: #38393a;
  line-height: 35px;
  margin-top: 3em !important;
}

.form-group label,
.form-group input {
  font-size: 12px;
}

.form-group {
  margin-top: 5px !important;
}

.row-bottom-margin {
  margin-bottom: 10px;
}

.selectContainer {
  width: 25%;
  margin-left: 10%;
  margin-top: 3%;
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.is-sticky {
  position: fixed;
  right: 50px;
  bottom: 20px;
  z-index: 999;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

.containerCollectorMapping {
  height: 480px;
  overflow: auto;
  padding: 20px;
}

.nav-link {
  color: #fff;
}

.nav-link:focus,
.nav-link:hover {
  color: var(--black-color);
}

.navbar-nav .nav-link.active,
.navbar-nav .show>.nav-link {
  color: var(--black-color);
}

.copy img {
  height: 30px;
  width: 100px;
}


.user_input {
  position: relative;
  height: 40px;
}

.inputBoxIcondiv {
  border: 1px solid var(--lightgray-color);
  border-right: 0px;
  background-color: var(--whitesmoke-color);
  position: absolute;
  width: 14%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
}




.navbar,
.navbar-collapse {
  flex-direction: column;
}

.navbar-expand-lg .navbar-nav {
  flex-direction: column;
}

.navbar {
  height: 100vh;
}

.fts {
  font-size: 14px;
}

.adminheading {
  margin-top: 10%;
  margin-bottom: 1%;
  text-shadow: 1px 1px rgb(187, 214, 235);
}

.bod_mesages {
  text-align: start;
  font-size: 18px;
  color: var(--red-color);
}

.instructions {
  font-size: 18px !important;
}

.p0 {
  margin: 0 !important;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.total th {
  background-color: #e00462 !important;
  color: #fff !important;
  font-weight: 600 !important;
}

.total td {
  background: #3d3b3b !important;
  font-weight: 600 !important;
  text-align: center;
}

.confirmNumberText {
  text-align: center;
}

.confirmNumberText span {
  color: #e00462;
  font-size: 22px;
  font-weight: 600;
  font-family: sans-serif;
}




.tally_modal {
  height: 500px;
  display: block;
  overflow-y: scroll;
}

.center {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.center p {
  font-size: 36px;
}

.beat_file_sample {
  font-size: 15px;
}

.alert_message_style {
  background-color: var(--lightGolden-color);
  border-radius: 7px;
  font-size: 14px;
  line-height: 22px;
  color: var(--slategray-color);
}

/* ********************************* react bootstrap table styles  start***********************/
.userDropdown {
  background-color: var(--themeBlue-color) !important;
  border: 1px solid var(--themeBlue-color) !important;
}

.bottom_dropdown .dropdown-menu {
  background-color: var(--darkLightSkyBlue-color) !important;
  border: 1px solid var(--themeBlue-color) !important;
}

.bottom_dropdown .dropdown-menu li:hover {
  background-color: var(--darkLightSkyBlue-color) !important;
  color: var(--midnightblue-color) !important;
  border: 1px solid var(--themeBlue-color) !important;
}

#pageDropDown {
  color: var(--midnightblue-color) !important;
  border: 1px solid var(--themeBlue-color) !important;
  background-color: var(--darkLightSkyBlue-color) !important;
}

.bottom_dropdown .dropdown-menu a {
  color: var(--midnightblue-color) !important;
  text-decoration: none;
}



.row.react-bootstrap-table-pagination ul.dropdown-menu {
  background-color: var(--darkLightSkyBlue-color) !important;
  border: 1px solid var(--themeBlue-color) !important;
}

.row.react-bootstrap-table-pagination ul.dropdown-menu a {
  color: var(--midnightblue-color) !important;
  text-decoration: none;
}

.row.react-bootstrap-table-pagination ul.dropdown-menu li:focus,
.row.react-bootstrap-table-pagination ul.dropdown-menu li:hover {
  background-color: var(--darkLightSkyBlue-color) !important;
  color: var(--midnightblue-color) !important;
  border: 1px solid var(--themeBlue-color) !important;
}

span.react-bootstrap-table-pagination-total {
  padding: 0px 15px;
  color: #363062 !important;
  font-size: 14px;
  font-weight: "bold" !important;
}

.table.table-bordered input {
  width: 100%;
}

.pagination {
  gap: 8px !important;
}

.pagination a {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: var(--darkGray-color);
}

.page-item .page-link {
  border-radius: 4px !important;
  padding-left: 13px;
  padding-right: 13px;
}

.pagination>.active>a {
  font-weight: 700 !important;
  color: var(--themeBlue-color);
  background-color: var(--white-color) !important;
  border: solid 1px var(--themeBlue-color) !important;
}

.row.react-bootstrap-table-pagination button {
  background-color: var(--darkLightSkyBlue-color) !important;
  color: var(--midnightblue-color) !important;
  border: 1px solid var(--themeBlue-color) !important;
}

.react-bs-table-sizePerPage-dropdown button {
  width: 120px !important;
}

.react-bs-table-sizePerPage-dropdown ul {
  padding: 0px !important;
  width: 100px !important;
  border: 1px solid var(--themeBlue-color);
}

.react-bs-table-sizePerPage-dropdown ul li {
  padding: 5px 0px;
  border-bottom: 1px solid var(--white-color) !important;
}

.react-bs-table-sizePerPage-dropdown ul li:last-child {
  border-bottom: none !important;
}

.react-bs-table-sizePerPage-dropdown ul li a {
  box-sizing: border-box;
  padding: 5px 55px !important;
}

.dropdown-menu.show {
  margin-left: 0px !important;
  margin-top: 0px !important;
  text-align: center !important;
}

.table.table.table-bordered th {
  background-color: var(--lightgray-color);
  font-weight: 500;
  color: var(--midnightblue-color);
  padding: 0.6rem 1rem;
  vertical-align: middle;
}

.table-bordered> :not(caption)>* {
  border-width: 0px 0px !important;
}

.table-bordered> :not(caption)>*>* {
  border-width: 0px 0px !important;
}

.table.table.table-bordered tr:nth-child(even) {
  vertical-align: center;
  background-color: rgba(121, 102, 228, 0.03);
}

.table.table.table-bordered td {
  vertical-align: middle;
  padding: 0.5rem 1rem;
  font-size: 11px;
}

.table.table.table-bordered th span {
  color: var(--midnightblue-color) !important;
  font-size: 12px !important;
}

.table.table.table-bordered tfoot th {
  background-color: var(--slategray-color) !important;
  font-size: 14;
  color: var(--white-color);
}

.table.table-bordered .form-control {
  width: 100%;
}

.table.table-bordered label {
  width: auto !important;
}

.sidebarNavigation::-webkit-scrollbar {
  width: 0;
  display: none;
}

.react-bootstrap-table {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1% !important;
  justify-content: flex-start !important;
  overflow-x: auto;
}

.react-bootstrap-table::-webkit-scrollbar {
  height: 10px;
}

.react-bootstrap-table::-webkit-scrollbar-track {
  background: var(--darkLightSkyBlue-color) !important;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.react-bootstrap-table::-webkit-scrollbar-thumb {
  background: var(--lowSlate-color) !important;
  border-radius: 10px;
}

.tableScrollable {
  overflow-x: auto !important;
}

.tableScrollable::-webkit-scrollbar {
  height: 10px;
}

.tableScrollable::-webkit-scrollbar-track {
  background: var(--darkLightSkyBlue-color) !important;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.tableScrollable::-webkit-scrollbar-thumb {
  background: var(--lowSlate-color) !important;
  border-radius: 10px;
}

.react-bootstrap-table-pagination {
  width: 80%;
  margin-left: 10%;
  z-index: -99 !important;
}

/* ********************************* react bootstrap table styles end***********************/
.action_button_style {
  padding: 7px 7.5px !important;
}

@media (max-width: 420px) {
  .Auth-form-container {
    padding: 0px 10px;
  }

  .box-outer {
    width: 100%;
    height: 420px;
  }
}

@media (min-width: 768px) {
  .navbar {
    height: 100vh;
  }

  form input {
    margin-bottom: 0.7em;
  }
}

@media (max-width: 768px) {
  .navbar-collapse {
    flex-basis: 0% !important;
  }
}

@media screen and (max-width: 768px) and (min-width: 575px) {
  .navbar-toggler.collapsed {
    margin-bottom: 20px;
  }
}

@media (max-width: 575px) {
  .profileName {
    font-size: 16px;
  }

  .adminLogo {
    width: 35px;
  }

  .navbar {
    height: auto !important;
  }

  .user_input {
    height: 38px;
  }


  .confirmNumberText span {
    font-size: 18px !important;
  }

  .confirmNumberText h5 {
    font-size: 13px !important;
  }

  .formIntputLabel {
    font-size: 14px !important;
  }


  td button.btn.btn-danger {
    background-color: #dc3545;
  }
}

@media only screen and (min-width: 200px) and (max-width: 576px) {
  .menuTextStyle {
    font-size: 14px;
  }

  .innerMenuTextStyle {
    font-size: 13px;
  }

  .beat_file_sample {
    font-size: 13px;
  }

  .row.react-bootstrap-table-pagination {
    gap: 10px !important;
    margin-top: 10px !important;
  }

  span.react-bootstrap-table-pagination-total {
    padding: 0px 10px !important;
    font-size: 12px !important;
  }
}

@media only screen and (min-width: 220px) and (max-width: 991px) {
  main {
    width: 100%;
  }

  .menuTextStyle {
    font-size: 14.5px;
  }

  .innerMenuTextStyle {
    font-size: 13.5px;
  }

  .sideMenusCloseInDevices {
    width: 200px !important;
    position: fixed;
    left: -100% !important;
    top: 0% !important;
    z-index: 1099 !important;
    transition: 0.5s all;
  }

  .sideMenusOpenInDevices {
    width: 210px;
    position: fixed;
    left: 0%;
    top: 0%;
    z-index: 1099 !important;
    transition: 0.5s all;
    background-color: white !important;
  }

  .darkOverlayOnSideMenuClose {
    position: fixed;
    left: -100%;
    top: 0%;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1028;
  }

  .darkOverlayOnSideMenuOpen {
    left: 0%;
  }

  .scrollableSideMneu {
    height: 78vh !important;
  }
}