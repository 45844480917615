.active_button {
  color: var(--themeBlue-color);
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.deactive_button {
  color: var(--slategray-color);
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.active_border_style {
  border-bottom: 1px solid var(--themeBlue-color);
}
