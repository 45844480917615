.label_style {
  font-size: 14px;
  color: var(--slategray-color);
}
.text_style {
  font-size: 15px;
  color: var(--midnightblue-color) !important;
}
.style_row{
  border:1px solid var(--red-color);
}