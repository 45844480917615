.css-1h06qz8-control {
  border: 1px solid var(--whitesmoke-color) !important;
}

.border_div_style {
  border: 1px solid var(--whitesmoke-color) !important;
}
.add_color {
  color: var(--themeBlue-color);
  cursor: pointer;
}
